import styles from '../../../components/styles/tableLoader.module.css'
import TableLoader from '../../../components/TableLoader'
import Table from '../../../components/Tables'

const OrderDetailsLoader = () => {
  return (
    <div className='flex flex-col items-stretch justify-start gap-6'>
      <div className='flex items-end justify-between'>
        <div className='flex items-start justify-start gap-8'>
          <div>
            <span className='font-semibold text-xs'>Created:</span>
            <div className={`w-32 h-5 ${styles.loader}`} />
          </div>
          <div>
            <span className='font-semibold text-xs'>Updated:</span>
            <div className={`w-32 h-5 ${styles.loader}`} />
          </div>
        </div>
        <div className={`${styles.loader} w-24 h-7`} />
      </div>
      <div className={`${styles.loader} w-24 h-6 rounded-xl`} />
      <Table headers={['items', 'unit price', 'quantity', 'total']}>
        <tr>
          <td colSpan={4}>
            <TableLoader rows={4} />
          </td>
        </tr>
        <tr>
          <td />
          <td className='font-semibold text-lg w-40 px-2.5 py-1.5 bg-transparent_black'>Totals</td>
          <td className='w-40 bg-transparent_black' />
          <td className='w-40 bg-transparent_black' />
        </tr>
      </Table>
      <div className='flex flex-col items-end justify-start gap-2'>
        <p className='font-semibold text-xl'>Adjustments</p>
        <div className={`w-40 h-7 ${styles.loader}`} />
        <div className={`w-40 h-7 ${styles.loader}`} />
      </div>
      <div className={`w-56 h-12 rounded-lg ${styles.loader} self-end`} />
      <div className='flex flex-col items-stretch justify-start gap-4 max-w-[480px]'>
        <div>
          <p className='font-semibold text-sm'>Fulfillment Method:</p>
          <div className={`${styles.loader} h-10`} />
        </div>
        <div>
          <p className='font-semibold text-sm'>Shipping Address:</p>
          <div className={`${styles.loader} h-10`} />
        </div>
        <div>
          <p className='font-semibold text-sm'>Billing Address:</p>
          <div className={`${styles.loader} h-10`} />
        </div>
        <div>
          <p className='font-semibold text-sm'>Notes:</p>
          <div className={`${styles.loader} h-10`} />
        </div>
      </div>
    </div>
  )
}

export default OrderDetailsLoader
