import api from '../utils/api'

const authenticationService = {
  login: async (username, password) => {
    try {
      const response = await api.post('/user/login/', { email: username, password })

      localStorage.setItem('token', response.data.access)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      return response.data // Assuming you want to return some data after successful login
    } catch (error) {
      console.error('Login failed!', error)
      throw error // Re-throw the error to be handled by the caller
    }
  },
  logout: async () => {
    try {
      const response = await api.post('/user/logout/', {})
      return response.data
    } catch (error) {
      console.error('Logout error', error)
      throw error
    }
  }
}

export default authenticationService
