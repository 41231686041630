import CheckIcon from '../assets/icons/CheckBlack.svg'

const WarehouseRadioButton = ({ warehouse, isSelected = false, onClick }) => {
  return (
    <article className={`p-3 border ${isSelected ? 'border-black bg-grey-300' : 'border-grey'} rounded-lg cursor-pointer`} onClick={onClick}>
      <div className='flex items-start justify-between gap-4'>
        <h4 className='font-semibold text-lg'>{warehouse.name}</h4>
        <figure className={`flex items-center justify-center w-4 h-4 border rounded-full ${isSelected ? 'border-black bg-transparent_black' : 'border-grey bg-grey'}`}>
          {
            isSelected && <img src={CheckIcon} alt='Check Icon' className='w-[90%]' />
          }
        </figure>
      </div>
      <div>
        <p className='font-semibold text-sm'>Address:</p>
        <p className='font-normal text-sm'>{warehouse.address.location}</p>
      </div>
    </article>
  )
}

export default WarehouseRadioButton
