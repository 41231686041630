import Trash from '../assets/icons/TrashWhite.svg'
import Plus from '../assets/icons/Plus.svg'
import { useEffect, useRef } from 'react'

const NotesInput = ({ label, inputsNumber, notes, handleChange, addNote, removeNote, isDisabled = false }) => {
  const noteRefs = useRef([])

  useEffect(() => {
    noteRefs.current.forEach(textarea => {
      if (textarea) {
        adjustTextareaHeight(textarea)
      }
    })
  }, [notes])

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = '45px'
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  return (
    <div>
      <span className="capitalize text-sm font-semibold after:content-[':']">{label}</span>
      <div className='input_container flex flex-col items-stretch justify-start gap-4 mb-2'>
        {
          Array.from({ length: inputsNumber }).map((_, index) => (
            <div
              key={index}
              className='flex items-end justify-start gap-4'
            >
              <span>{index + 1}.</span>
              <textarea
                className={`w-full text-base py-2.5 border-black placeholder:text-grey-800 outline-none overflow-hidden ${!isDisabled ? 'px-4 border rounded-lg resize-y' : 'px-0 border-b resize-none'}`}
                name={`note-${index + 1}`}
                value={notes[index].note}
                readOnly={isDisabled}
                onChange={(e) => handleChange(index, e)}
                ref={(element) => { noteRefs.current[index] = element }}
              />
              {
                !isDisabled && (
                  <button
                    type='button'
                    className='w-6 h-6 p-0.5 bg-custom_red rounded'
                    onClick={() => removeNote(index)}
                  >
                    <img src={Trash} alt='Trash icon' />
                  </button>
                )
              }
            </div>
          ))
        }
      </div>
      {
        !isDisabled && (
          <button
            type='button'
            className='w-6 h-6 border border-black rounded'
            onClick={addNote}
          >
            <img src={Plus} alt='Trash icon' />
          </button>
        )
      }
    </div>
  )
}

export default NotesInput
