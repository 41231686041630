import api from '../utils/api'

const fulfillmentMethodsService = {
  list: async () => {
    try {
      const response = await api.get('/admin/fulfillments/')
      return response.data
    } catch (error) {
      console.error('Error fetching Fulfillment Methods!', error)
    }
  }
}

export default fulfillmentMethodsService
