import { useEffect, useState } from 'react'

const TableLoader = ({ rows = 10 }) => {
  const [activeRow, setActiveRow] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveRow(prevIndex => (prevIndex + 1) % rows)
    }, 300)

    return () => clearInterval(interval)
  }, [])

  return (
    <section className='relative'>
      {
        Array.from({ length: rows }).map((_, index) => (
          <div key={index} className={`row h-11 ${activeRow === index && 'bg-grey'} transition-bg duration-300`} />
        ))
      }
      <p className='font-semibold text-lg absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-10'>Loading...</p>
    </section>
  )
}

export default TableLoader
