const SelectInput = ({ id, label = 'label', name, options = [], value = 0, onChange, isDisabled = false }) => {
  return (
    <div className='flex flex-col'>
      <label htmlFor={id} className='capitalize text-sm font-semibold after:content-[":"]'>{label}</label>
      <select
        className={`text-base border-black placeholder:text-grey outline-none py-2.5 ${!isDisabled ? 'px-4 border rounded-lg' : 'px-0 border-b opacity-100 appearance-none'}`}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
      >
        <option value={0} disabled>Select {label}</option>
        {
          options.map((option, index) => (
            <option key={index} value={option.id}>{option.name}</option>
          ))
        }
      </select>
    </div>
  )
}

export default SelectInput
