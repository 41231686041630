import XIcon from '../assets/icons/XIcon.svg'

export const StatusBadge = ({ isActive = false, isDotType = false, text }) => {
  return (
    <div className={`${isActive ? 'bg-custom_green' : 'bg-custom_red'} ${isDotType ? 'w-3.5 h-3.5 rounded-full' : 'flex items-center justify-center min-w-20 w-fit px-2 py-1 text-center rounded-xl'}`}>
      {
        !isDotType && <span className='text-xs font-semibold text-white'>{text || (isActive ? 'Active' : 'Disabled')}</span>
      }
    </div>
  )
}

export const DateBadge = ({ label = 'date', date = 'Jan. 01, 1973' }) => {
  return (
    <div className='flex flex-col items-start justify-start'>
      <span className="text-xs font-medium after:content-[':'] capitalize">{label}</span>
      <span className='text-sm font-medium'>{date}</span>
    </div>
  )
}

export const FilterBadge = ({ filterLabel, filterValue, onClick }) => {
  return (
    <div className='flex items-center justify-between gap-4 min-w-28 w-fit px-1.5 py-[2px] rounded bg-grey-600'>
      <p className='text-xs'><span className='font-semibold'>{filterLabel}:</span> {filterValue}</p>
      <button onClick={onClick}>
        <img src={XIcon} alt='x mark' className='h-4 w-4' />
      </button>
    </div>
  )
}
