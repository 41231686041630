export const getFormattedDate = (dateString) => {
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  })

  return (formattedDate)
}
