import api from '../utils/api'

const organizationService = {
  getInfo: async (organizationName) => {
    try {
      const response = await api.get(`/crm/organizations/?search=${organizationName}`)
      return response.results
    } catch (error) {
      console.error('Error fetching organization!', error)
    }
  },
  getOrganizationUsers: async (organizationName) => {
    try {
      const response = await api.get(
        `/crm/organization-users/?search=${organizationName}`
      )
      return response.results
    } catch (error) {
      console.error('Error fetching organization users!', error)
    }
  },
  getSalesReps: async (id) => {
    try {
      const response = await api.get(
        `/crm/organization-sales-reps/?organization_id=${id}`
      )
      return response.results
    } catch (error) {
      console.error('Error fetching organization sales reps!', error)
    }
  },
  dashboardStats: async (startDate, endDate) => {
    try {
      const response = await api.get(`/dashboard/wholesale-organization-stats/?start_date=${startDate}&end_date=${endDate}`)
      return response
    } catch (error) {
      console.log('Error fetching stats.', error)
    }
  }
}

export default organizationService
