import { toast } from 'react-toastify'
import api from '../utils/api'

const userService = {
  details: async () => {
    try {
      const response = await api.get('/user/wholesale-user/')
      return response.data
    } catch (error) {
      console.error('Error fetching user data.', error)
    }
  },
  update: async (payload) => {
    try {
      const response = await api.put('/user/wholesale-user/', payload)
      return response
    } catch (error) {
      console.error('Error updating user.', error)
    }
  },
  updatePhoto: async (payload) => {
    try {
      const response = await api.patch('/user/wholesale-user/', payload)
      return response
    } catch (error) {
      console.error('Error updating photo.', error)
    }
  },
  changePassword: async (payload) => {
    try {
      const response = await api.post('/user/password/change/', payload)
      return response
    } catch (error) {
      console.error('Error updating password.')
    }
  },
  resetPasswordSend: async (payload) => {
    try {
      const response = await api.post('/user/wholesale-password-reset/', payload)
      toast.success(`Password recovery code sent to ${payload.email}`)
      return response
    } catch (error) {
      console.error('Error reseting password.')
    }
  },
  resetPasswordDone: async (payload) => {
    try {
      const response = await api.post('/user/wholesale-password-reset-confirm/', payload)
      return response
    } catch (error) {
      console.error('Error reseting password.')
    }
  }
}

export default userService
