const RadioButton = ({ label, isSelected = true, onClick }) => {
  return (
    <div className='flex items-center justify-start gap-2 w-fit cursor-pointer' onClick={onClick}>
      <div className='flex items-center justify-center w-3.5 h-3.5 rounded-full border border-black'>
        <div className={`${isSelected ? 'block' : 'hidden'} w-2 h-2 rounded-full bg-black`} />
      </div>
      <span className='capitalize leading-none select-none'>{label}</span>
    </div>
  )
}

export const RadioGroup = ({ label, options = [], selectedOption, onChange }) => {
  return (
    <div>
      <span className='capitalize text-sm font-semibold after:content-[":"]'>{label}</span>
      <ul className='flex flex-col items-start justify-start gap-2'>
        {
          options.map((op, index) => (
            <li key={index}><RadioButton label={op.label} isSelected={op.value === selectedOption} onClick={() => onChange(op.value)} /></li>
          ))
        }
      </ul>
    </div>
  )
}

export default RadioButton
