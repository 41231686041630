import DialCodes from '../utils/countriesDialCode.json'

const InputPhoneNumber = ({ label = 'Phone Number', code, number, isDisabled = false, onChange }) => {
  return (
    <div className='flex flex-col items-start justify-start'>
      <span className='capitalize text-sm font-semibold'>{label}:</span>
      <div className={`flex justify-start ${isDisabled ? 'items-end, gap-0' : 'items-start gap-4'} w-full`}>
        <select
          name='country_code'
          className={`text-base border-black placeholder:text-grey-800 outline-none py-2.5 ${!isDisabled ? 'px-4 border rounded-lg' : 'px-0 border-b opacity-100 appearance-none'}`}
          value={code}
          disabled={isDisabled}
          onChange={onChange}
        >
          {
            DialCodes.map((country, index) => (
              <option value={country.dial_code} key={index}>{country.emoji} {country.dial_code}</option>
            ))
          }
        </select>
        <input
          name='phone_number_part'
          className={`flex-1 text-base border-black placeholder:text-grey-800 outline-none py-2.5 ${!isDisabled ? 'px-4 border rounded-lg' : 'px-0 border-b'}`}
          type='number'
          value={number}
          readOnly={isDisabled}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default InputPhoneNumber
