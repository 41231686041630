import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DateBadge } from '../../../components/Badges'
import { getFormattedDate } from '../../../utils/dateFormatter'
import InputGroup from '../../../components/InputGroup'
import NotesInput from '../../../components/NotesInput'
import Table, { TableCell } from '../../../components/Tables'
import financeService from '../../../services/financeService'
import TableLoader from '../../../components/TableLoader'

const PaymentDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [paymentAllocations, setPaymentAllocations] = useState()

  const location = useLocation()
  const payment = location.state.payment

  useEffect(() => {
    fetchAllocations()
  }, [])

  const fetchAllocations = async () => {
    setIsLoading(true)
    const response = await financeService.allocationsByPayment(payment.payment.id)
    if (response) {
      setPaymentAllocations(response)
      setIsLoading(false)
    } else {
      setPaymentAllocations([])
      setIsLoading(false)
    }
  }

  const notesQuantity = Object.keys(payment.payment.notes || {}).length
  const notes = Object.entries(payment.payment.notes || {}).map(([key, value]) => {
    return { note: value }
  })

  return (
    <div className='flex max-md:flex-col items-start max-md:items-stretch justify-start gap-8'>
      <section className='min-w-60 flex-[2]'>
        <div className='flex items-start justify-start gap-4 mb-8'>
          <DateBadge label='Created' date={getFormattedDate(payment.payment.created)} />
          <DateBadge label='Log' date={getFormattedDate(payment.payment.log.created)} />
        </div>
        <div className='flex flex-col items-stretch justify-start gap-4'>
          <InputGroup
            id='amount'
            label='amount'
            value={`$${payment.payment.amount}`}
            isDisabled
          />
          <InputGroup
            id='amount_used'
            label='amount allocated'
            value={`$${payment.payment.amount - payment.payment.remaining_amount}`}
            isDisabled
          />
          <InputGroup
            id='remaining_amount'
            label='remaining amount'
            value={`$${payment.payment.remaining_amount}`}
            isDisabled
          />
          <InputGroup
            id='payment_type'
            label='payment type'
            value={payment.payment.payment_type}
            isDisabled
          />
          <NotesInput
            label='notes'
            inputsNumber={notesQuantity}
            notes={notes}
            isDisabled
          />
        </div>
      </section>
      <section className='flex-[3]'>
        <div>
          <h2 className='font-semibold text-xl'>Orders Allocated</h2>
          <div className='max-h-64 overflow-y-scroll'>
            <Table headers={['Order ID', 'total', 'paid', 'to pay']}>
              {
                isLoading
                  ? (
                    <tr>
                      <td colSpan={4}>
                        <TableLoader rows={4} />
                      </td>
                    </tr>
                    )
                  : paymentAllocations?.length > 0
                    ? paymentAllocations?.map(allocation =>
                      <tr key={allocation.id} className='odd:bg-grey'>
                        <TableCell>{allocation.order}</TableCell>
                        <TableCell>${allocation.order_detail.total_amount.toFixed(2)}</TableCell>
                        <TableCell>${(allocation.order_detail.total_amount - allocation.order_detail.unpaid_amount).toFixed(2)}</TableCell>
                        <TableCell>${allocation.order_detail.unpaid_amount.toFixed(2)}</TableCell>
                      </tr>
                    )
                    : (
                      <tr>
                        <td colSpan={4}>
                          <div className='flex flex-col items-center justify-center gap-4 p-8'>
                            <p className='text-lg'>This payment has not been allocated to any order.</p>
                          </div>
                        </td>
                      </tr>
                      )
              }
            </Table>
          </div>
        </div>
      </section>
    </div>
  )
}

export default PaymentDetailsPage
