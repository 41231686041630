import { Navigate, useNavigate } from 'react-router-dom'
import InputGroup from '../../../components/InputGroup'
import Button from '../../../components/Button'
import { useAuth } from '../../../context/AuthContext'
import { useState } from 'react'
import userService from '../../../services/userService'

const ForgotPasswordPage = () => {
  const { loggedIn } = useAuth()

  const [isEmailFilled, setIsEmailFilled] = useState(false)
  const [emailError, setEmailError] = useState({
    status: false,
    message: ''
  })
  const [payload, setPayload] = useState({
    email: ''
  })
  const navigate = useNavigate()

  const handleChange = (event) => {
    const { name, value } = event.target

    setPayload(prev => ({
      ...prev,
      [name]: value
    }))

    if (value !== '') {
      setIsEmailFilled(true)
    } else {
      setIsEmailFilled(false)
    }
  }

  const sendEmail = async () => {
    if (payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      setEmailError(prev => ({
        ...prev,
        status: false
      }))
      const response = await userService.resetPasswordSend(payload)
      if (response) {
        navigate('/reset-password/new-password')
      } else {
        setEmailError(prev => ({
          ...prev,
          status: true,
          message: `User whit the email ${payload.email} does not exist.`
        }))
      }
    } else {
      setEmailError(prev => ({
        ...prev,
        status: true,
        message: 'Insert a valid email.'
      }))
    }
  }

  return loggedIn
    ? <Navigate to='/' />
    : (
      <div className='flex items-center justify-center h-screen'>
        <div className='flex flex-col items-start justify-start gap-4 max-w-[30rem] w-full p-8'>
          <p className='font-normal text-lg'><strong className='text-2xl'>Forgot your password?</strong> Please enter your email.</p>
          <p className='font-normal text-base'>You will receive an email to reset your password.</p>
          <div className='self-stretch flex flex-col gap-4'>
            <InputGroup label='Email' id='email' name='email' type='email' value={payload.email} onChange={handleChange} />
            {
            emailError.status && <p className='text-sm text-custom_red'>{emailError.message}</p>
          }
            <div className='self-end flex items-center justify-end gap-4'>
              <Button value='Cancel' type='button' btnStyle='secondary' onClick={() => navigate('/login')} />
              <Button value='Send' onClick={sendEmail} isDisabled={!isEmailFilled} />
            </div>
          </div>
        </div>
      </div>
      )
}

export default ForgotPasswordPage
