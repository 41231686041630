import { toast } from 'react-toastify'
import api from '../utils/api'

const addressesService = {
  organizationAddresses: async (organizationID) => {
    try {
      const response = await api.get(`/crm/organization-addresses/?organization_id=${organizationID}`)
      return response.results
    } catch (error) {
      console.error('Error fetching organization addresses!', error)
    }
  },
  create: async (payload) => {
    try {
      const response = await api.post('/crm/organization-addresses-details/', payload)
      if (response.id) {
        toast.success('Address created successfully!')
        return true
      }

      toast.error('Error creating address')
      return false
    } catch (error) {
      console.error('Error creating address', error)
    }
  },
  getCountries: async () => {
    try {
      const response = await api.get('/geoapp/countries/')
      return response.data
    } catch (error) {
      console.error('Error fetching countries', error)
    }
  }
}

export default addressesService
