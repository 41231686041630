import { useEffect, useState } from 'react'
import Table, { TableCell, TableContainer } from '../../components/Tables'
import InputGroup from '../../components/InputGroup'
import { StatusBadge } from '../../components/Badges'
import { useNavigate } from 'react-router-dom'
import InfoIcon from '../../assets/icons/InfoIcon.svg'
import ordersService from '../../services/ordersService'
import { getFormattedDate } from '../../utils/dateFormatter'
import { RadioGroup } from '../../components/RadioGroup'
import { useAuth } from '../../context/AuthContext'
import TableLoader from '../../components/TableLoader'

const OrdersPage = () => {
  const [orders, setOrders] = useState()
  const [ordersFiltered, setOrdersFiltered] = useState(orders)
  const [isLoading, setIsLoading] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [tableHeaders, setTableHeaders] = useState(['ID', 'added', 'approval', 'created by', 'fulfillment method', 'fulfillment state', 'details'])
  const [totalPages, setTotalPages] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (windowWidth < 768) {
      setTableHeaders(['ID', 'added', 'approval', 'fulfillment state', 'details'])
    } else {
      setTableHeaders(['ID', 'added', 'approval', 'created by', 'fulfillment method', 'fulfillment state', 'details'])
    }
  }, [windowWidth])

  const { user } = useAuth()

  const fetchOrders = async () => {
    setIsLoading(true)
    const response = await ordersService.list(user.organizations[0]?.organization, page, pageSize)
    if (response) {
      setOrders(response.results)
      setTotalPages(response.meta.page_total)
      setPageSize(response.meta.page_size)
      setIsLoading(false)
    } else {
      setOrders([])
      setTotalPages(1)
      setIsLoading(false)
    }
  }

  const updatePagination = (newPage, newSize) => {
    setPage(newPage)
    setPageSize(newSize)
  }

  useEffect(() => {
    fetchOrders()
  }, [page, pageSize])

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (orders) {
      setOrdersFiltered(orders)
    }
  }, [orders])

  const navigate = useNavigate()

  const filtersInitialState = {
    order_id: '',
    date_from: '',
    date_to: '',
    approval: 'all',
    username: '',
    fulfillment_method: 'all',
    fulfillment_state: 'all'
  }

  const [filters, setFilters] = useState(filtersInitialState)

  const handleChange = (event) => {
    const { name, value } = event.target

    setFilters({
      ...filters,
      [name]: value
    })
  }

  const [approvalStateSelected, setapprovalStateSelected] = useState('all')

  const approvalStates = [
    {
      label: 'all',
      value: 'all'
    },
    {
      label: 'approval',
      value: 'approval'
    },
    {
      label: 'not approval',
      value: 'not_approval'
    }
  ]

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      approval: approvalStateSelected
    }))
  }, [approvalStateSelected])

  const [fulfillmentMethodSelected, setFulfillmentMethodSelected] = useState('all')

  const fulfillmentMethods = [
    {
      label: 'all',
      value: 'all'
    },
    {
      label: 'shipping',
      value: 'ship'
    },
    {
      label: 'delivery',
      value: 'delivery'
    },
    {
      label: 'pickup',
      value: 'pickup'
    }
  ]

  useEffect(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      fulfillment_method: fulfillmentMethodSelected
    }))
  }, [fulfillmentMethodSelected])

  const filterFields = (
    <>
      <InputGroup
        id='order_id'
        name='order_id'
        label='order ID'
        value={filters.order_id}
        onChange={handleChange}
      />
      <div>
        <span className='capitalize text-sm font-semibold'>Created:</span>
        <InputGroup
          id='date_from'
          name='date_from'
          label='from'
          type='date'
          value={filters.date_from}
          onChange={handleChange}
        />
        <InputGroup
          id='date_to'
          name='date_to'
          label='to'
          type='date'
          value={filters.date_to}
          onChange={handleChange}
        />
      </div>
      <RadioGroup
        label='approval'
        options={approvalStates}
        selectedOption={filters.approval}
        onChange={setapprovalStateSelected}
      />
      <InputGroup
        id='username'
        name='username'
        label='username'
        value={filters.username}
        onChange={handleChange}
      />
      <RadioGroup
        label='fulfillment method'
        options={fulfillmentMethods}
        selectedOption={filters.fulfillment_method}
        onChange={setFulfillmentMethodSelected}
      />
    </>
  )

  const filterOrders = () => {
    let filtered = orders

    if (filters.order_id !== '') {
      filtered = filtered.filter(order => order.id.toString().includes(filters.order_id))
    }
    if (filters.date_from) {
      filtered = filtered.filter(order => new Date(order.order.created) >= new Date(filters.date_from))
    }
    if (filters.date_to) {
      filtered = filtered.filter(order => new Date(order.order.created) <= new Date(filters.date_to))
    }
    if (filters.username !== '') {
      filtered = filtered.filter(order => {
        const user = order.order.fulfillment_state_history.find(state => state.state === 'order created')?.username || order.order.fulfillment_state_history[0]?.username
        if (user.toLowerCase().includes(filters.username.toLowerCase())) {
          return order
        }
        return null
      })
    }
    if (filters.approval !== 'all') {
      if (filters.approval === 'approval') {
        filtered = filtered.filter(order => order.order.approval)
      } else {
        filtered = filtered.filter(order => !order.order.approval)
      }
    }
    if (filters.fulfillment_method !== 'all') {
      filtered = filtered.filter(order => order.order.fulfillment_method_detail.name.toLowerCase() === filters.fulfillment_method)
    }

    setOrdersFiltered(filtered)
  }

  const resetFilters = () => {
    setFilters(filtersInitialState)
    setOrdersFiltered(orders)
  }

  return (
    <TableContainer
      filterFields={filterFields}
      filtersInitialState={filtersInitialState}
      filters={filters}
      setFilters={setFilters}
      showAddButton={false}
      applyFilters={filterOrders}
      resetFilters={resetFilters}
      totalPages={totalPages}
      updatePagination={updatePagination}
    >
      <Table headers={tableHeaders}>
        {
          ordersFiltered?.length > 0 && !isLoading
            ? ordersFiltered?.map((obj, index) => (
              <tr className='odd:bg-grey' key={index}>
                <TableCell>{obj.id}</TableCell>
                <TableCell>{getFormattedDate(obj.order.created)}</TableCell>
                <TableCell>
                  <div className='flex items-center justify-center'>
                    <StatusBadge isDotType isActive={obj.order.approval} />
                  </div>
                </TableCell>
                {
                    windowWidth >= 768 &&
                      <>
                        <TableCell>
                          {
                            obj.order.fulfillment_state_history.find(state => state.state === 'order created')?.username || obj.order.fulfillment_state_history[0]?.username || '----------'
                          }
                        </TableCell>
                        <TableCell><span className='capitalize'>{obj.order.fulfillment_method_detail.name}</span></TableCell>
                      </>
                  }
                <TableCell>
                  <span className='capitalize'>
                    {
                        obj.order.fulfillment_state_history[
                          obj.order.fulfillment_state_history.length - 1
                        ]?.state || '----------'
                      }
                  </span>
                </TableCell>
                <TableCell>
                  <div className='flex items-center justify-center'>
                    <button
                      className='h-8 w-8 p-1'
                      onClick={() => navigate(`details/${obj.id}`)}
                    >
                      <img src={InfoIcon} alt='Icon for information' />
                    </button>
                  </div>
                </TableCell>
              </tr>
            ))
            : (
              <tr>
                <td colSpan={tableHeaders.length}>
                  {
                      !isLoading
                        ? (
                          <div className='flex flex-col items-center justify-center gap-4 p-8'>
                            <p className='font-semibold text-lg'>No orders found</p>
                            <p className='text-sm'>{orders?.length !== 0 ? 'Try changing or resetting the filters' : 'There was an error loading the orders'}</p>
                          </div>
                          )
                        : <TableLoader />
                    }
                </td>
              </tr>
              )
        }
      </Table>
    </TableContainer>
  )
}

export default OrdersPage
