import api from '../utils/api'
import { toast } from 'react-toastify'

const financeService = {
  organizationPayments: async (organizationID, pageSize, page) => {
    try {
      const response = await api.get(`/finance/organization-payments/?organization-id=${organizationID}&page_size=${pageSize}&page=${page}`)
      return response
    } catch (error) {
      console.error('Error fetching Organization Payments')
    }
  },
  createPayment: async (payload) => {
    try {
      const response = await api.post('/finance/organization-payments/', payload)
      if (response.id) {
        toast.success('Payment created successfully')
        return true
      } else {
        toast.error('Error creating Payment!')
        return false
      }
    } catch (error) {
      toast.error('Error creating Payment!')
      console.error('Error creating payment', error)
    }
  },
  detail: async (paymentID) => {
    try {
      const response = await api.get(`/finance/organization-payments/${paymentID}/`)
      return response
    } catch (error) {
      console.error('Error fetching payment details.', error)
    }
  },
  allocationsByPayment: async (paymentID) => {
    try {
      const response = await api.get(`/finance/order-allocation-payments/?payment_id=${paymentID}`)
      return response.results
    } catch (error) {
      console.error('Error fetching payment allocations.', error)
    }
  }
}

export default financeService
